import { lazy } from "react";

const User = lazy(() => import("pages/admin"));
const Default = lazy(() => import("pages/default"));
const NotFound = lazy(() => import("pages/notFound"));
const Client = lazy(() => import("pages/clients"));
const Vacancy = lazy(() => import("pages/vacancies"));
const Team = lazy(() => import("pages/team"));
const Portfolio = lazy(() => import("pages/portfolio"));
const Settings = lazy(() => import("pages/settings"));
const LocalizationPanel = lazy(() => import("pages/localizationPanel"));
// const Login = lazy(() => import("pages/login"));

export interface IRoute {
  path: string;
  key?: string | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    key: "welcome",
    title: "Welcome",
    element: <Default />,
  },
  {
    path: "/profile",
    key: "profile",
    title: "Profile",
    element: <User />,
  },
  {
    path: "/clients",
    key: "clients",
    title: "Klientlar",
    element: <Client />,
  },
  {
    path: "/vacancies",
    key: "vacancies",
    title: "Vakansiyalar",
    element: <Vacancy />,
  },
  {
    path: "/team",
    key: "team",
    title: "Jamoa",
    element: <Team />,
  },
  {
    path: "/portfolio",
    key: "portfolio",
    title: "Portfolio",
    element: <Portfolio />,
  },
  {
    path: "/settings",
    key: "settings",
    title: "Sozlamalar",
    element: <Settings />,
  },
  {
    path: "/translations",
    key: "translations",
    title: "Tarjimalar",
    element: <LocalizationPanel />,
  },
  {
    path: "*",
    key: "*",
    title: "",
    element: <NotFound />,
  },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
