import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message } from "antd";
import { GetProp, UploadFile, UploadProps } from "antd";
import { FieldProps } from "formik";
import axios from "axios";
import { storage } from "services";
import { useHooks } from "hooks";

// Define file type
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

// Helper to convert file to base64
const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

// Component Props
interface Props extends FieldProps<any, any> {
  label?: string;
  className?: string;
  errorMessage?: string;
  rootClassName?: string;
  limit?: number;
  listType?: UploadProps["listType"];
  successed?: boolean;
  setSuccess?: (value: boolean) => void;
  customDelete?: boolean;
  onSuccess?: () => void;
  hasSuccess?: boolean;
}

const FileUploader = (props: Props) => {
  const { get } = useHooks();
  const [isDark] = useState(storage.get("theme") !== "light");

  // Destructure props
  const {
    form: { setFieldValue },
    field: { name, value },
    limit = 1,
    listType = "picture-card",
    onSuccess = () => { },
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>(get(value, "url") ? [
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: get(value, "url", ""),
    },
  ] : []);

  // Handle preview modal cancel
  const handleCancel = () => setPreviewOpen(false);

  // Handle file preview
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(file.name || "Image Preview");
    setPreviewOpen(true);
  };

  // Handle file list change
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // Custom upload request
  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://images.hypernova.uz/api/upload/single",
        formData
      );

      if (response.status === 200 && response.data.file.url) {
        message.success("File uploaded successfully");
        const fileUrl = response.data.file.url; // Get the file URL from the response
        setFieldValue(name, { url: fileUrl, id: file.uid });  // Set the field value with the URL of the uploaded file
        setFileList([
          ...fileList,
          {
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: fileUrl,
          },
        ]);
        onSuccess();
      } else {
        message.error("File upload failed");
        onError("Upload error");
      }
    } catch (error) {
      message.error("Upload failed. Please try again.");
      onError(error);
    }
  };


  // Upload button UI
  const uploadButton = (
    <div>
      <PlusOutlined style={isDark ? { color: "#9EA3B5" } : { color: "#000" }} />
      <div className="mt-2">Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        listType={listType}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default FileUploader;
